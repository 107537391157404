<script>
import list1 from "@/assets/provider/1.png"
import list2 from "@/assets/provider/2.png"
import list3 from "@/assets/provider/3.png"
import list4 from "@/assets/provider/4.png"
import list5 from "@/assets/provider/5.png"
import list6 from "@/assets/provider/6.png"
export default {
    name: "",
    data(){
        return{
            list:[list1,list2,list3,list4,list5,list6]
        }
    }
}
</script>

<template>
    <div class="provider">
        <div class="top">
            <div class="banner">{{$t('new.gameProvider')}}</div>
            <img class="bg" src="../assets/provider/b.png" alt="">
        </div>
        <div class="list">
            <img v-for="(item,i) in list" :key="i" class="img-item" :src="item" alt="">
        </div>
    </div>
</template>

<style scoped lang="scss">
.top {
    width: 100%;
    height: 80px;
    display: flex;
    background-color: #213743;
    .banner{
        width: 40%;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        line-height: 80px;
    }
    .bg{
        height: 100%;
    }
}

.list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .img-item{
        background-color: #2D4555;
        width: 112px;
        height: 44px;
        margin: 18px 0 8px 0;
        border-radius: 6px;
    }
}

</style>
